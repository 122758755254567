@import '../../../assets/sass/app.scss';

    main#banners {

        #filter {
            width:      100%;
            margin: 0 0 15px;

            display:       flex;
            flex-flow: row wrap;

            align-items:     center;
            justify-content: space-between;

            .inputs {
                display:       flex;
                flex-direction: row;

                align-items:     flex-start;
                justify-content: flex-start;

                select  { width: 300px; margin: 10px 20px 10px 0; }
                .search {
                    display:  block;
                    position: relative;

                    input { width: 300px; height: 38px; margin: 10px 0; }
                    button { 
                        padding: 0;
                        margin:  0;

                        border:               0;
                        background: transparent;
                        
                        position:    absolute; 
                        top: 17px; right: 7px;

                        color:    $gray;
                        cursor: pointer;

                        @include transition;
                        
                        &:hover { color: $blue; }
                        &.cancel { right: 33px; * { color: $lightGray; }}
                    }
                }
            }
            button { padding: 7px 35px; }
        }

        #infos {
            width:   100%;
            padding: 15px 0;

            display:       flex;
            flex-flow: row wrap;

            align-items:       center;
            justify-content: flex-end;

            span {
                font-weight: 300;
                font-size: .9rem;
                color:     $gray;

                strong { font-weight: 500; color: $darkGray; }
            }
        }

        #list {
            table {
                td, th {
                    vertical-align: middle;
                    font-size:       .9rem;

                    &.images {
                        display:                 flex;
                        justify-content: space-around;

                        figure {
                            width:  50px;
                            height: 50px;

                            overflow:   hidden;
                            border-radius: 5px;
                            border: 1px solid silver;

                            img { width: 100%; height: 100%; object-fit: cover; object-position: center; }
                        }
                    }
                }
                tfoot {
                    div {
                        width:         100%;
                        display:       flex;
                        flex-direction: row;

                        align-items:     center;
                        justify-content: center;

                        font-weight: 300;
                        font-size: .9rem;

                        .labels { display: block; width: fit-content; padding: 0 15px; }
                        svg     { font-size: .9rem; }
                    }
                }
            }
        }
    }