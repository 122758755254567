@import '../../assets/sass/app.scss';

    span#loading {
        width:   calc(100%);
        
        height:  100%;
        
        position: fixed;
        top:  0;  left:  0;
        
        display:          flex;
        flex-direction: column;

        align-items:     center;
        justify-content: center;

        z-index: 99;

        background-color: rgba(255, 255, 255, .8);

        .icon {
            color: $green;
            margin-left: 273px;
        }
    }

    @media screen and (max-width: 768px) {
        span#loading {
            .icon {
                margin-left: 273px;
            }
        }
    }