
    #title {
        width:         100%;
        margin: 0 auto 15px;

        display:       flex;
        flex-direction: row;

        align-items:     center;
        justify-content: flex-start;

        h1 {
            font-weight:  600;
            font-size: 1.8rem;
            margin:    0 10px;
        }
        .back {
            display:       flex;
            flex-direction: row;

            align-items:     center;
            justify-content: center;

            cursor: pointer;

            > span { 
                visibility: hidden; 
                opacity:    0; 

                font-size:  .9rem; 
                transition: all .5s ease-in-out; 
                
                width:     100%;
                max-width: 0;
                
                overflow:   hidden;
                margin-left:  -5px;
            }
            > svg  { font-size: 2rem; color: black; transition: all .5s ease-in-out; }

            &:hover {
                max-width: fit-content;
                > span { 
                    visibility: visible; 
                    opacity:          1; 
                    max-width:    300px;
                }
            }
        }
    }
