@import '../../assets/sass/app.scss';

    section#login {
        
        width:  100vw;
        height: 100vh;

        display:           flex; 
        flex-direction:  column;
        align-items:     center;
        
        justify-content:  center;
        background-color: $white;

        position: absolute;
        z-index:     110px;

        .wrapper {
            figure { 
                @include cover(250px, auto); 
                margin: 25px auto; 
            }
            label {
                font-weight: 700;
                font-size: .8rem;
            }
            button {
                width:  100%;
                margin: 10px auto;
            }
            .feedback {
                margin: 15px auto;
            }
            .lost-pass {
                width: fit-content;
                display:      flex;

                float: right;

                font-size: .9rem;
                color:     $blue;

                cursor: pointer;
                margin:  15px 0;

                text-decoration: underline;
                @include transition;

                &:hover {
                    color: $green;
                    text-decoration: none;
                }
            }
            .eye {
                width: 100%;
                margin:   0;

                display:     block;
                position: relative;

                svg { 
                    position:     absolute;
                    top: 08px; right: 10px;
                    
                    color:    $blue;
                    cursor: pointer;
                }
            }
        }

    }

    @include media-breakpoint-down(sm) { 
        section#login {
            .wrapper {
                width:  90%;
            }
        }
    }

    @include media-breakpoint-up(lg) { 
        section#login {
            .wrapper {
                width:  40%;
            }
        }
    }