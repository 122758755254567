
    $colorBase: #2A2B41;
    $white:     #ffffff;
    $black:     #2A2B41;

    $red:        #FB1F00;
    $yellow:     #FFBA05;
    $green:      #209869;
    $lightGreen: #9CD33B;
    
    $blue:      #0091CE;
    $lightBlue: #C0E3F3;
    
    $silver:    #F1EFEF;
    $lightGray: #d6d6d6;
    $gray:      #707070;
    $darkGray:  #4E5464;

    $megaSena:  #209869;
    $timeMania: #9CD33B;
    $quina:     #6B5BE2;
    $lotoFacil: #930089;
    $lotoMania: #FF8C2A;
    $loteca:    #F16165;
    $duplaSena: #490B1B;
    $diaDeSorte:#CFAA29;
