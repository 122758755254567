.user-avatar {
  display: flex;
  align-items: center;
}

.user-avatar .username {
  font-size: 12px;
}

.user-avatar .user-info {
  margin-left: 10px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
}

.user-avatar .avatar-image {
  border-radius: 100%;
}

.actions {
  display: flex;
  gap: 10px;
  height: 100%;
  align-items: center;
  min-height: 80px;
}

.actions .action {
  background: none !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: .2s;
}

.actions .action:hover {
  opacity: 0.7;
}

.actions .action:disabled {
  opacity: 0.5 !important;
  cursor: not-allowed;
  box-shadow: none !important;
}

.actions .action:disabled .MuiSvgIcon-root {
  fill: #000 !important;
  opacity: 0.5 !important;
}

.actions .action.approve {
  .MuiSvgIcon-root {
    fill: #19D25C;
  }
}

.actions .action.unapproved {
  .MuiSvgIcon-root {
    fill: #DF2A3C;
  }
}

.actions .action.exclude {
  .MuiSvgIcon-root {
    fill: #DF2A3C;
  }
}

.table tr {
  position: relative;
}

.table .col-date {
  width: 20%;
}

.table .col-user {
  width: 20%;
}

.table .col-status {
  width: 10%;
}

.table .col-deposition {
  width: 100%;
}

.table tr .loading-row {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  opacity: 0.8;
}
