@import '../../assets/sass/app.scss';

.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  margin-bottom: 120px;
}

.button-group .exclude {
  background-color: #DF2A3C;
}

.status-icon {
  border-radius: 100%;
  width: 24px;
  height: 24px;
  background-color: #E6E8EC;
}

.apply-global-filter {
  margin-bottom: 12px;
}

.ql-disabled {
  opacity: 0.5;
}

.quill {
  height: 66px !important;
  width: 100% !important;
}

.react-fine-uploader-file-input {
  cursor: pointer;
}

.ql-editor {
  min-height: 66px !important;
  width: 100% !important;
}

.ql-container {
  overflow: hidden;
  border-radius: 12px;
}

.row-full {
  width: 100%;
}

.game-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.game-actions {
  margin-left: 100px;
}

.game-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 18px 24px;
  border: 1px solid #E6E8EC;
  border-radius: 4px;
}

.game-infos {
  display: flex;
  align-items: center;
  flex: 1
}


.game-name {
  color: #353945;
  font-size: 16px;
  min-width: 250px;
  margin-left: 24px;
}

.rs-picker-menu {
  z-index: 100 !important;
}

.game-description {
  display: flex;
  flex-direction: column;
}

.progress-bar-widget {
  width: 100%;
}

.description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.react-rich-editor {
  flex: 1;
  max-width: 600px;
  margin-left: 100px;
}

.react-rich-editor.disabled {
  opacity: 0.5;
}

.game-list {
  margin-bottom: 24px !important;
}

.row-group-title {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  margin: 24px 0;
}

.row-group-title-count {
  font-weight: 700;
}

.description .description-label {
  font-size: 16px;
  color: #353945
}

.progress-label {
  font-size: 12px;
  color: #353945;
}

.table-main {
  height: 15em;
  table {
    @apply text-sm w-full h-full flex rounded-lg overflow-hidden;
    flex-flow: column;
  }

  table th {
    &:first-child {
      padding: 0.75rem !important;
    }
    padding: 0.75rem 0 !important;
    @apply border-none;
  }

  table thead {
    flex: 0 0 auto;
    margin-bottom: 0 !important;
    background: rgb(234, 235, 238) !important;
    margin-bottom: 0 !important;
    @apply w-full;
  }

  table thead tr {
    border: none !important;
  }

  table tbody {
    flex: 1 1 auto;
    @apply block overflow-y-auto;
  }

  table tbody tr {
    border-top: none !important;
    margin-bottom: 0 !important;
    @apply w-full border-b border-gray-200;
  }

  table tbody td {
    border: none !important;
    padding: 0.75rem !important;
  }

  table thead,
  table tbody tr {
    @apply table-fixed table;
  }
}