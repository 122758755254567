@import '../../../../assets/sass/app.scss';

    main#view {
        
        .row          { width: 100%; }
        .feedback     { .MuiCollapse-entered { margin: 15px auto; }}

        #title {
            width:         100%;
            margin: 0 auto 15px;

            display:       flex;
            flex-direction: row;

            align-items:     center;
            justify-content: flex-start;

            h1 {
                font-weight:  600;
                font-size: 1.8rem;
                margin:    0 10px;
            }
            .back {
                display:       flex;
                flex-direction: row;

                align-items:     center;
                justify-content: center;

                cursor: pointer;

                > span { 
                    visibility: hidden; 
                    opacity:    0; 

                    font-size:  .9rem; 
                    transition: all .5s ease-in-out; 
                    
                    width:     100%;
                    max-width: 0;
                    
                    overflow:   hidden;
                    margin-left:  -5px;
                }
                > svg  { font-size: 2rem; color: black; transition: all .5s ease-in-out; }

                &:hover {
                    max-width: fit-content;
                    > span { 
                        visibility: visible; 
                        opacity:          1; 
                        max-width:    300px;
                    }
                }
            }
        }

        #data {
            width:         100%;
            margin: 0 auto 15px;
            padding:  15px 20px;

            display:       flex;
            flex-flow: row wrap;

            align-items:     center;
            justify-content: flex-start;

            border: 1px solid $silver;
            border-radius:        5px;

            span {
                min-width:  100px;
                margin: 10px 15px;

                display:       flex;
                flex-direction: row;

                align-items:     center;
                justify-content: flex-start;

                font-weight: 300;
                font-size: .9rem;
                color:     $gray;

                strong { font-weight: 500; margin-right: 5px; }
            }
            .descript {
                display:   block;
                width:       80%; 
                line-height: 1.4;
            }
        }

        #jackpot-tabs-tabpane-games {
            position: relative;

            h1 {
                font-weight:  600;
                font-size: 1.4rem;
            }
            > div {
                display: flex;
                flex-direction:  column;
                align-items:     center;
                justify-content: center;
                
                .line {
                    display:         flex;
                    justify-content: space-between;
                    
                    width:   100%;
                    padding: 10px;

                    border-bottom: 1px solid $lightGray;
                    font-size:     1rem;
                    color:         $darkGray;

                    .id { 
                        width: fit-content;
                        display:     block;
                    }
                    .numbers { 
                        display:     flex;
                        align-items: center;
                        flex-flow: row wrap;

                        span { 
                            width:  35px;
                            height: 35px;

                            margin: 0 5px;

                            display:          flex;
                            flex-direction: column;

                            align-items:     center;
                            justify-content: center;

                            background-color: $lightGray;
                            color:            $darkGray;

                            border-radius: 100%;
                            text-align:  center;

                            font-size: .8rem;
                            padding:     5px;

                            @include transition;
                            cursor: default; 

                            &.active { background-color: $blue; color: $white; }
                            &:hover  { background-color: $gray; color: $white; }
                        }
                    }
                    .complement {
                        width: 106px !important;

                        margin:   0 0 0 5px !important;
                        padding:  7px 15px  !important;

                        display:          flex;
                        flex-direction: column;

                        align-items:     center;
                        justify-content: center;

                        background-color: $lightGray;
                        color:            $darkGray;

                        border-radius: 7px !important;
                        text-align:  center;

                        font-size: .8rem;

                        @include transition;
                        cursor:     default; 

                        text-transform: uppercase;
                    }
                    .links { display: block; }
                }
                .pagination { 
                    width:   100%; 
                    display: flex; 
                    
                    flex-direction:  row;
                    align-items:     center;
                    justify-content: center;

                    margin: 30px auto; 
                }
            }
        }

        #jackpot-tabs-tabpane-receipt {
            width:         95%;
            position: relative;

            margin: 0 auto 50px;
            padding:       15px;

            h1 {
                font-weight:  600;
                font-size: 1.4rem;
            }
            
            .form {
                width:   90%;
                margin:  30px auto 50px;
                padding: 25px;

                border: 1px solid $silver;
                border-radius:        5px;
            }
            .files {
                width:    90%;
                margin:  auto;

                svg { margin: 0 10px; }
            }
        }

    }

    .confirmation { .link { padding: 10px;}}