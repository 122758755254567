@import '../../assets/sass/app.scss';

    footer {
        width: 100%;
        
        position: absolute;
        bottom: 0; left: 0;
        padding: 15px 40px;

        font-weight: 300;
        font-size: .8rem;

        background-color: $darkGray;
        color:               $white;

        @include transition;
    }