@import 'abstracts/mixins.scss';
@import 'abstracts/fonts.scss';
@import 'abstracts/colors.scss';
@import 'animations/dots.scss';

@import "libraries/bootstrap/bootstrap.scss";

@import "libraries/animate";
@import "transitions/fade";
@import "transitions/slide";

html {
    box-sizing: border-box;
}
*, *::before, *::after {
    box-sizing:                             inherit;
    outline:                        none !important;
    -webkit-tap-highlight-color:         $colorBase;
}
body {
    width:   100vw;
    margin:  0;
    padding: 0;

    font-family:   $fontBase;
    color: 		  $colorBase;
    background-color: $white;

    font-size: 	 16px;
    line-height: 1.2em;
    letter-spacing: normal;

    font-weight: 400;
    max-width:   100%;

    top:  0;  left:  0;
    overflow-x: hidden;
    overflow-y:   auto;

    -webkit-text-size-adjust: none;
    -webkit-font-smoothing:   subpixel-antialiased;
    -moz-osx-font-smoothing:  colorBasescale;
    text-rendering:           optimizeLegibility;

    /**
    * Resets
    **/
        a { &:hover { text-decoration: none; }}

        &.no-scroll       { overflow: hidden; }
        &.no-scroll-login { overflow: hidden; }
        &.no-scroll-cart  { overflow: hidden; }
        &.no-scroll-menu  { overflow: hidden; }

        .link {
            cursor: pointer;

            color:           $green;
            text-decoration: underline;

            @include transition;

            &:hover {
                color: $green;
            }
        }
        .srv-validation-message {
            font-size: .7rem;
            color:      $red;
            padding:   5px 0;
        }
        .form-control {
            font-size: .9rem;
        }
        .content {
            display:          flex;
            flex-direction: column;

            align-items:        flex-start;
            justify-content: space-between;

            @include transition;
        }

        main {
            width:        100%;
            min-height:  100vh;
        }

        @include media-breakpoint-down(sm) {
            position: absolute;

            #root { overflow: hidden;  }
            .desktop { display:  none; }
            .mobile  { display: block; }
            .content {
                width:    100vw;
                padding: 0 15px;
            }

            main { padding: 30px 15px !important; }
        }

        @include media-breakpoint-between(sm,md) {
            position: relative;

            .desktop { display:  none; }
            .mobile  { display: block; }
            .content {
                width:     100%;
                max-width: 70vw;

                position: absolute;
                top:      0;
                right:    0;
                left:  auto;
            }
            .menu-opened {
                width:     100%;
                max-width: 100%;
                left:      auto;
                right:        0;
	              padding-bottom: 50px;

                @include transition;
            }
            main { padding: 30px 15px !important; }
        }

        @include media-breakpoint-up(lg) {
            position: relative;

            .desktop { display: block; }
            .mobile  { display:  none; }
            .content {
                width:    100%;
                position: absolute;
                top:      0;
                right:    0;
                left:  auto;
            }
            .menu-opened {
                width:     100%;
                max-width: calc(100% - 16vw);

                left:      16vw;
                right:        0;
	              padding-bottom: 50px;

                box-sizing: border-box;
                @include    transition;
            }
            main { padding: 30px !important; }
        }
}
