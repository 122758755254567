    
    /** Transition pages Left **/
    .slide-enter {
        transform: translateX(100%);
    }
    .slide-enter-active {
        transform:  translateX(0%);
        transition: transform 1000ms ease-in-out;
    }
    .slide-exit {
        transform: translateX(0%);
    }
    .slide-exit-active {
        transform:  translateX(-100%);
        transition: transform 1000ms ease-in-out;
    }

    /** Transition pages Left **/
    .slideUp-enter {
        transform: translateY(100%);
    }
    .slideUp-enter-active {
        transform:  translateY(0%);
        transition: transform 1000ms ease-in-out;
    }
    .slideUp-exit {
        transform: translateY(0%);
    }
    .slideUp-exit-active {
        transform:  translateY(-100%);
        transition: transform 1000ms ease-in-out;
    }