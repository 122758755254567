@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

.MuiAlert-message {
	@apply w-full;
}

.refine-query-builder-select-button,
.refine-query-builder-inputs-date,
.refine-query-builder-inputs-date-double,
.refine-query-builder-inputs-date-relative,
.refine-query-builder-inputs-number,
.refine-query-builder-inputs-number-double,
.refine-query-builder-inputs-text {
	position: relative;
	width: 100%;
	border-radius: 0.375rem;
	border-width: 1px;
	--tw-border-opacity: 1;
	border-color: rgb(209 213 219 / var(--tw-border-opacity));
	--tw-bg-opacity: 1;
	background-color: rgb(255 255 255 / var(--tw-bg-opacity));
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 0.75rem;
	text-align: left;
}

.refine-query-builder-select-button:focus,
.refine-query-builder-inputs-date:focus,
.refine-query-builder-inputs-date-double:focus,
.refine-query-builder-inputs-date-relative:focus,
.refine-query-builder-inputs-number:focus,
.refine-query-builder-inputs-number-double:focus,
.refine-query-builder-inputs-text:focus {
	--tw-border-opacity: 1;

	border-color: rgb(32 152 105 / var(--tw-border-opacity));
}

@media (min-width: 640px) {
	.refine-query-builder-select-button,
	.refine-query-builder-inputs-date,
	.refine-query-builder-inputs-date-double,
	.refine-query-builder-inputs-date-relative,
	.refine-query-builder-inputs-number,
	.refine-query-builder-inputs-number-double,
	.refine-query-builder-inputs-text {
		font-size: 0.875rem;
		line-height: 1.25rem;
	}
}

.refine-query-builder-group {
	margin-bottom: 1rem;
}

.refine-query-builder-group > :not([hidden]) ~ :not([hidden]) {
	--tw-space-y-reverse: 0;
	margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
	margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.refine-query-builder-group {
	border-radius: 0.25rem;
	--tw-bg-opacity: 1;
	background-color: rgb(249 250 251 / var(--tw-bg-opacity));
	padding: 1rem;
}

.refine-query-builder-group-wrapper {
	/* */
}

.refine-query-builder-group-add-criterion-button {
	margin-top: 0.75rem;
	display: flex;
	align-items: center;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	font-size: 0.75rem;
	line-height: 1rem;
	@apply text-primary;
}

.refine-query-builder-group-add-criterion-button-text {
	/* */
}

.refine-query-builder-add-group-button {
	display: inline-flex;
	align-items: center;
	border-radius: 0.375rem;
	border-width: 1px;
	border-color: transparent;
	padding-left: 1rem;
	padding-right: 1rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 500;
  @apply text-white bg-primary/80;
}

.refine-query-builder-add-group-button:hover {
  @apply bg-primary;
}

.refine-query-builder-add-group-button:focus {
	outline: 1px solid transparent;
	outline-offset: 1px;
	--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
	--tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
	box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
	--tw-ring-opacity: 1;
	--tw-ring-color: rgb(32 152 105 / var(--tw-ring-opacity));
	--tw-ring-offset-width: 1px;
}

.refine-query-builder-criterion > :not([hidden]) ~ :not([hidden]) {
	--tw-space-y-reverse: 0;
	margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
	margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.refine-query-builder-criterion-wrapper {
	display: flex;
	align-items: center;
}

.refine-query-builder-criterion-wrapper > :not([hidden]) ~ :not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-right: calc(0.75rem * var(--tw-space-x-reverse));
	margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.refine-query-builder-criterion-remove-criterion-button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	--tw-text-opacity: 1;
	color: rgb(107 114 128 / var(--tw-text-opacity));
}

.refine-query-builder-criterion-errors {
	margin-left: 5rem;
	list-style-type: disc;
	--tw-text-opacity: 1;
	color: rgb(239 68 68 / var(--tw-text-opacity));
}

.refine-query-builder-criterion-errors-error {
	/* */
}

.refine-query-builder-condition {
	@apply flex flex-1;
}

.refine-query-builder-condition > :not([hidden]) ~ :not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-right: calc(1rem * var(--tw-space-x-reverse));
	margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.refine-query-builder-select {
	/* */
}

.refine-query-builder-select > div {
	position: relative;
}

.refine-query-builder-select-wrapper {
	/* */
}

.refine-query-builder-select-custom-options {
	/* */
}

.refine-query-builder-select-custom-options-wrapper {
	/* */
}

.refine-query-builder-select-listbox {
	position: absolute;
	z-index: 10;
	margin-top: 0.25rem;
	max-height: 15rem;
	width: 100%;
	overflow: auto;
	border-radius: 0.375rem;
	--tw-bg-opacity: 1;
	background-color: rgb(255 255 255 / var(--tw-bg-opacity));
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	font-size: 1rem;
	line-height: 1.5rem;
	--tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	--tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
	--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
	--tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
	box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
	--tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
	--tw-ring-opacity: 0.05;
}

.refine-query-builder-select-listbox:focus {
	outline: 1px solid transparent;
	outline-offset: 1px;
}

@media (min-width: 640px) {
	.refine-query-builder-select-listbox {
		font-size: 0.875rem;
		line-height: 1.25rem;
	}
}

.refine-query-builder-select-listbox-transition-leave {
	transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
	transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
	transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 100ms;
	transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.refine-query-builder-select-listbox-transition-leave-from {
	opacity: 1;
}

.refine-query-builder-select-listbox-transition-leave-to {
	opacity: 0;
}

.refine-query-builder-select-listbox-wrapper {
	/* */
}

.refine-query-builder-select-listbox-item {
	position: relative;
	cursor: default;
	-webkit-user-select: none;
	user-select: none;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 2.5rem;
	padding-right: 1rem;
}

.refine-query-builder-select-listbox-item-active {
	--tw-bg-opacity: 1;
	background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.refine-query-builder-select-listbox-item-text {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.refine-query-builder-select-listbox-item-icon {
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	display: flex;
	align-items: center;
	padding-left: 0.75rem;
	--tw-text-opacity: 1;
	color: rgb(79 70 229 / var(--tw-text-opacity));
}

.refine-query-builder-select-listbox-item-icon > svg {
	height: 1.25rem;
	width: 1.25rem;
}

.refine-query-builder-select-button {
	/* */
}

.refine-query-builder-select-button > span {
	display: block;
	min-height: 1.25rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.refine-query-builder-select-button-placeholder {
	/* */
}

.refine-query-builder-select-button-selected {
	/* */
}

.refine-query-builder-select-button-icon {
	pointer-events: none;
	position: absolute;
	top: 0px;
	bottom: 0px;
	right: 0px;
	display: flex;
	align-items: center;
	padding-right: 0.5rem;
}

.refine-query-builder-select-button-icon > svg {
	height: 1.25rem;
	width: 1.25rem;
	--tw-text-opacity: 1;
	color: rgb(156 163 175 / var(--tw-text-opacity));
}

.refine-query-builder-select-multi {
	/* */
}

.refine-query-builder-select-multi-button {
	/* */
}

.refine-query-builder-select-multi-button-placeholder {
	/* */
}

.refine-query-builder-select-multi-button-selected {
	/* */
}

.refine-query-builder-select-multi-button-deselect {
	/* */
}

.refine-query-builder-select-multi-button-deselect-icon {
	/* */
}

.refine-query-builder-select-multi-button-deselect-icon-wrapper {
	/* */
}

.refine-query-builder-select-multi-button-icon {
	/* */
}

.refine-query-builder-select-multi-button-icon-wrapper {
	/* */
}

.refine-query-builder-inputs-date {
	/* */
}

.refine-query-builder-inputs-date-double {
	/* */
}

.refine-query-builder-inputs-date-double-wrapper {
	display: flex;
	align-items: baseline;
}

.refine-query-builder-inputs-date-double-wrapper > :not([hidden]) ~ :not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-right: calc(0.75rem * var(--tw-space-x-reverse));
	margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.refine-query-builder-inputs-date-double-joiner {
	/* */
}

.refine-query-builder-inputs-date-relative {
	/* */
}

.refine-query-builder-inputs-date-relative-wrapper {
	display: flex;
}

.refine-query-builder-inputs-date-relative-wrapper > :not([hidden]) ~ :not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-right: calc(0.75rem * var(--tw-space-x-reverse));
	margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.refine-query-builder-inputs-date-calendar {
	/* */
}

.refine-query-builder-inputs-date-calendar-icon {
	/* */
}

.refine-query-builder-inputs-date-error {
	/* */
}

.refine-query-builder-inputs-date-error-icon {
	/* */
}

.refine-query-builder-inputs-number {
	/* */
}

.refine-query-builder-inputs-number-double {
	/* */
}

.refine-query-builder-inputs-number-double-wrapper {
	display: flex;
	align-items: baseline;
}

.refine-query-builder-inputs-number-double-wrapper > :not([hidden]) ~ :not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-right: calc(0.75rem * var(--tw-space-x-reverse));
	margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.refine-query-builder-inputs-number-double-joiner {
	/* */
}

.refine-query-builder-inputs-text {
	/* */
}
