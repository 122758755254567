.Tooltip {
	@apply text-white text-2xs max-w-[250px] leading-snug rounded shadow-sm px-3 py-1.5 text-center;

	&.primary {
		@apply !bg-primary;
	}

	&.secondary {
		@apply !bg-dark-primary;
	}

	&.success {
		@apply !bg-success;
	}

	&.error {
		@apply !bg-error-light;
	}

	&.warning {
		@apply !bg-warning;
	}
}

.Tooltip.default {
	background-color: rgba(0, 0, 0, 0.75);
}
