@import '../../../assets/sass/app.scss';

.poper-bank-data {
	width: 300px;
	padding: 5px;
	margin:  0px;

	border: 1px solid $silver;
	border-radius:       15px;

	display:       flex;
	flex-flow: row wrap;

	align-items:       flex-start;
	justify-content: space-around;

	strong { width: 40%; padding: 5px; font-weight: 700; font-size: .9rem; text-transform: capitalize; }
	span   { width: 60%; padding: 5px; font-weight: 300; font-size: .9rem; text-transform: capitalize; }
	.close-poper { font-size: .8rem; position: absolute; top: 5px; right: 5px; cursor: pointer; }
}

    main#withdraw {

        #title {
            width:         100%;
            margin: 0 auto 15px;

            display:       flex;
            flex-direction: row;

            align-items:     center;
            justify-content: flex-start;

            h1 {
                font-weight:  600;
                font-size: 1.8rem;
                margin:    0 10px;
            }
            .back {
                display:       flex;
                flex-direction: row;

                align-items:     center;
                justify-content: center;

                cursor: pointer;

                > span {
                    visibility: hidden;
                    opacity:    0;

                    font-size:  .9rem;
                    transition: all .5s ease-in-out;

                    width:     100%;
                    max-width: 0;

                    overflow:   hidden;
                    margin-left:  -5px;
                }
                > svg  { font-size: 2rem; color: black; transition: all .5s ease-in-out; }

                &:hover {
                    max-width: fit-content;
                    > span {
                        visibility: visible;
                        opacity:          1;
                        max-width:    300px;
                    }
                }
            }
        }

        #filter {
            width:  100%;
            margin: 20px 0 10px;

            display:       flex;
            flex-flow: row wrap;

            align-items:     center;
            justify-content: space-between;

            .inputs {
                width: 100vw;
                display:       flex;
                flex-direction: column;

                align-items:     flex-start;
                justify-content: flex-start;

                .search {
                    width: 100vw;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    button {
                        &:hover { color: $blue; }
                        &.cancel {
                            right: 33px; * { color: $lightGray; }
                            padding: 0;
                            margin:  0;

                            border:               0;
                            background: transparent;

                            position:    absolute;
                            top: 17px; right: 7px;

                            color:    $gray;
                            cursor: pointer;

                            @include transition;
                        }
                    }
                    .inputs-box{
                        select  { width: 250px; margin: 10px 0px 10px 0; }
                        display:  flex;
                        position: relative;

                        width: 100vw;

                        gap: 0.5rem;

                        input  { width: 250px; height: 38px; margin: 10px 0; }

                        .date-box{
                            display: flex;
                            flex-direction: column;

                            .data-label{
                                font-size: 0.9rem;
                            }
                        }
                    }
                }

                .submit-box{
                    width: 100%;
                }
            }
            button { padding: 7px 35px; }


        }

        #infos {
            width:   100%;
            padding: 15px 0;

            display:       flex;
            flex-flow: row wrap;

            align-items:       center;
            justify-content: flex-end;

            span {
                font-weight: 300;
                font-size: .9rem;
                color:     $gray;

                strong { font-weight: 500; color: $darkGray; }
            }
        }

        #process {
            width: 100%;

            display: flex;
            align-items: row wrap;
            justify-content: flex-end;
        }

        #list {
            table {
                width:         100%;
                margin:        auto;
                margin-bottom: 50px;

                th {
                    font-weight: 500;
                    font-size: .9rem;
                    color: $darkGray;
                }
                td {
                    font-weight:  300;
                    font-size:  .8rem;
                    color:  $darkGray;

                    &.reference { font-weight: 400; color: $black; }
                    &.empty     { font-weight: 300; font-size: .9rem; }
                }
                .actions{
                    display: flex;
                }
                tbody {
                    th { svg { margin: 0 5px; &.done { color: $blue; }}}
                }
                tfoot {
                    div {
                        width:         100%;
                        display:       flex;
                        flex-direction: row;

                        align-items:     center;
                        justify-content: center;

                        font-weight: 300;
                        font-size: .9rem;

                        .labels { display: block; width: fit-content; padding: 0 15px; }
                        svg     { font-size: .9rem; }
                    }
                }
            }
        }

    }

    @include media-breakpoint-down(sm) {
        .search {
            display: flex;
            flex-direction: column;
        }
    }
