@import '../../../../assets/sass/app.scss';

    main#new-game {
        
        .row { width: 100%; }
        
        form {
            width:   100%;
            padding: 0;

            label {
                font-weight: 700;
                font-size: .8rem;
                color:     $gray;
                margin-top: 15px;
            }
            small {
                font-weight: 300;
                font-size: .7rem;
                color: $darkGray;
            }
            input {
                font-weight: 300;
                font-size: .8rem;
            }
            .react-datepicker-wrapper { width: 100%; }
        }

    }