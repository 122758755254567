@import '../../assets/sass/app.scss';

    section#menu {
	      a {
		      color: inherit;
		      &.active {
			      color: $green !important;
            background: rgba(32, 152, 105, 0.2);
		      }
	      }

        width:   100%;

        position: fixed;
        top: 0; left: 0;

        max-width: 30px;
        z-index:    100;

        background-color: $white;

        @include transition;

        .close {
            color: $gray;
        }

        .toggle-menu {
            cursor:    pointer;
            position: absolute;

            top: 10px; left: 3px;
            z-index:         110;
        }

        .box {
            width:  100%;
            height: 100%;

            position: absolute;
            top:  0;  left:  0;

            visibility: hidden;
            overflow:   hidden;
            opacity:         0;

            @include transition;

            #logo {
                width:  180px;
                height:  80px;

                overflow:  hidden;
                margin: 15px auto;

                img {
                    display: block;

                    width:  100%;
                    height: 100%;

                    object-fit:     contain;
                    object-position: center;
                }
            }

            #welcome {
                font-weight:   300;
                font-size:   .8rem;

                text-align: center;
                padding:    15px 0;

                letter-spacing: 1px;

                span {
                    &::after {
                        content:   ".";
                        animation: dots 1s steps(5, end) infinite;
                    }
                }
            }

            hr {
                width:  90%;
                margin: 5px auto;

                border:         0px;
                border-bottom:  1px solid $silver;
            }

            nav {
                width:   100%;
                height:  100%;

                margin:  30px 15px;

                ul {
                    list-style: none;
                    display:    flex;

                    width:         80%;
                    padding-left: 15px;

                    flex-direction:  column;
                    align-items: flex-start;

                    > li {
                        width:      100%;
                        font-weight: 300;

                        padding: 10px 0;
                        display:   flex;

                        flex-flow: row wrap;
                        align-items: center;

                        border-bottom: 1px solid $silver;
                        cursor: pointer;

                        span,
                        a {
                            font-size:  1rem;

                            @include transition;
                            cursor:     pointer;
                        }

                        > svg {
                            margin:    0 5px;
                            overflow: hidden;
                            font-size:  1rem;

                            @include transition;
                        }

                        &:hover {
                            > a   { color: $green; }
                            > svg { color: $green; }
                            .exit { color: $green; }
                        }

                        .submenu {
                            padding-left: 30px;
                            > li { border: 0; a { font-size: .9rem; } svg { font-size: .8rem !important; }}
                        }
                    }
                }
            }
        }

        &.active {
            .box { opacity: 1; visibility: visible; }
        }

        @include media-breakpoint-down(sm) {
            height: 10vh;
            &.active { max-width: 60vw; height: 100vh; box-shadow: -3px 3px 12px 0px rgba(0, 0, 0, 0.231); }
        }

        @include media-breakpoint-between(sm,md) {
            height: 100vh;
            .toogle-button { display: none; }
            &.active { max-width: 30vw; height: 100vh; box-shadow: -3px 3px 12px 0px rgba(0, 0, 0, 0.231); }
        }

        @include media-breakpoint-up(lg) {
            height: 100%;
            .toogle-button { display: none; }
            &.active { max-width: 16vw; box-shadow: -3px 3px 12px 0px rgba(0, 0, 0, 0.231); }
        }

    }
