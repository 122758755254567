
    /** Transition pages **/
    .fade-enter {
        opacity: 0.01;
    }
    
    .fade-enter.fade-enter-active {
        opacity: 1;
        transition: opacity 400ms ease-in;
    }
    
    .fade-exit {
        opacity: 0;
    }
    
    .fade-exit.fade-exit-active {
        opacity: 0;
    }