@import '../../assets/sass/app.scss';

    main#concursos {

        #title {
            width:         100%;
            margin: 0 auto 15px;

            display:       flex;
            flex-direction: row;

            align-items:     center;
            justify-content: flex-start;

            h1 {
                font-weight:  600;
                font-size: 1.8rem;
                margin:    0 10px;
            }
            .back {
                display:       flex;
                flex-direction: row;

                align-items:     center;
                justify-content: center;

                cursor: pointer;

                > span { 
                    visibility: hidden; 
                    opacity:    0; 

                    font-size:  .9rem; 
                    transition: all .5s ease-in-out; 
                    
                    width:     100%;
                    max-width: 0;
                    
                    overflow:   hidden;
                    margin-left:  -5px;
                }
                > svg  { font-size: 2rem; color: black; transition: all .5s ease-in-out; }

                &:hover {
                    max-width: fit-content;
                    > span { 
                        visibility: visible; 
                        opacity:          1; 
                        max-width:    300px;
                    }
                }
            }
        }

        #filter {
            width:      100%;
            margin: 0 0 15px;

            display:       flex;
            flex-flow: row wrap;

            align-items:     center;
            justify-content: space-between;

            .inputs {
                display:       flex;
                flex-direction: row;

                align-items:     flex-start;
                justify-content: flex-start;

                select  { width: 300px; margin: 10px 20px 10px 0; }
                .search {
                    display:  block;
                    position: relative;

                    input { width: 300px; height: 38px; margin: 10px 0; }
                    button { 
                        padding: 0;
                        margin:  0;

                        border:               0;
                        background: transparent;
                        
                        position:    absolute; 
                        top: 17px; right: 7px;

                        color:    $gray;
                        cursor: pointer;

                        @include transition;
                        
                        &:hover { color: $blue; }
                        &.cancel { right: 33px; * { color: $lightGray; }}
                    }
                }
            }
            button { padding: 7px 35px; }
        }

        #infos {
            width:   100%;
            padding: 15px 0;

            display:       flex;
            flex-flow: row wrap;

            align-items:       center;
            justify-content: flex-end;

            span {
                font-weight: 300;
                font-size: .9rem;
                color:     $gray;

                strong { font-weight: 500; color: $darkGray; }
            }
        }

        #list {
            table {
                width:         100%;
                margin:        auto;
                margin-bottom: 50px;

                th {
                    font-weight: 500;
                    font-size: .9rem;
                    color: $darkGray;
                }
                td {
                    padding: 5px;
                    
                    font-weight:  300;
                    font-size:  .8rem;
                    
                    color:       $darkGray;
                    vertical-align: middle;

                    &.reference { font-weight: 400; color:    $black; }
                    &.empty     { font-weight: 300; font-size: .9rem; }
                }
                tbody {
                    th { svg { margin: 0 5px; }}
                }
                tfoot {
                    div {
                        width:         100%;
                        display:       flex;
                        flex-direction: row;

                        align-items:     center;
                        justify-content: center;

                        font-weight: 300;
                        font-size: .9rem;

                        .labels { display: block; width: fit-content; padding: 0 15px; }
                        svg     { font-size: .9rem; }
                    }
                }
            }
        }

    }