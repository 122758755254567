@import '../../../../assets/sass/app.scss';

    main#edit-concourse {
        
        .row { width: 100%; }
        
        form {
            width:   100%;
            padding: 0 0 30px;

            label {
                font-weight: 700;
                font-size: .8rem;
                color:     $gray;
                margin-top: 15px;
            }
            small {
                font-weight: 300;
                font-size: .7rem;
                color: $darkGray;
            }
            input {
                font-weight: 300;
                font-size: .8rem;
            }
            .image {
                position: relative;
                display:      flex;

                flex-direction:  column;
                align-items:     center;
                justify-content: center;

                label { 
                    width: 100%; 
                }
                figure {
                    display:   block;
                    margin:     auto;
                    
                    overflow:   hidden;
                    border-radius: 5px;

                    position: relative;
                    
                    &.mobile  { width:  80%; height: 300px; }
                    &.desktop { width: 100%; height: 200px; }

                    img { width: 100%; height: 100%; object-fit: cover; object-position: center; }
                    span {
                        width:  25px;
                        height: 25px;

                        position:   absolute;
                        top: 5px; right: 5px;

                        background-color: white;
                        border-radius:       100%;

                        padding: 5px;

                        display:          flex;
                        flex-direction: column;

                        align-items:     center;
                        justify-content: center;
        
                        cursor: pointer;
                        transition: all .5s ease-in-out;

                        svg {        
                            color:     gray;
                            font-size: 1.2rem;
                            transition: all .5s ease-in-out;
                        }
                        &:hover { width: 30px; height: 30px; svg{ font-size: 1.4rem; color: red; }}
                    }
                }
            }
            .react-datepicker-wrapper { width: 100%; }
        }

    }